import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";

const SubjectAcademicDetails = (props: any) => {
  const {records, subject} = props;
  const flattendRecords = () => {
    let data: any[] = [];
    Object.keys(records).forEach((form: string) => {
      records[form].forEach((record: any) => {
        Object.keys(record.records.results).forEach((type: string) => {
          let subRecord = record.records.results[type][subject];
          if (subRecord) {
            let label = form + " - "  + type;
            let row: any[] = [label];
            if (form == "S5" || form == "S6") {
              let mks: any[] = [];
              Object.keys(record.records.results[type][subject].papers).forEach((paper: string) => {
                let rec = record.records.results[type][subject].papers[paper];
                mks.push(`P${paper}: ${rec.total} | ${rec.grd}`);
              })
              row.push(mks.join(" <> "))
            } else {
              row.push(subRecord.mark);
            } 
            row.push(subRecord.score);
            data.push(row);
          }
        })
      })
    })
    return data;
  }
  return (
    <React.Fragment>
      <div>
        <Container fluid>
              <table className="table table-bordered">
                <thead>
                  <tr><th>CATEGORY</th><th>MARK</th><th>SCORE</th></tr>
                  {flattendRecords().map((row: any[]) => {
                    return <tr>
                      <td>{row[0]}</td>
                      <td>{row[1]}</td>
                      <td>{row[2]}</td>
                    </tr>
                  })}
                </thead>
              </table>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubjectAcademicDetails;
