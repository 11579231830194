import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { fullUrl } from "../../utils/Helpers";
import { Loading } from "../../components/common/Loading";
import XModal from "../../components/common/XModal";
import SubjectAcademicDetails from "./SubjectAcademicDetails";

const request = new ApiCore();

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState<any>(null);
  const [results, setResults] = useState<any>(null);
  const [selectedRecords, setSelectedRecords] = useState<any>(null);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [subjectDialog, setSubjectDialog] = useState<boolean>(false);
  const [loadingResults, setLoadingResults] = useState<boolean>(true);

  useEffect(() => {
    loadStudent();
    loadResults();
  }, []);

  const loadStudent = () => {
    request
      .get(`${Endpoints.students}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setStudent(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const loadResults= () => {
    request
      .get(`${Endpoints.studentsResults}?id=${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setResults(data);
        setLoadingResults(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingResults(false);
      });
  };
  
  return (
    <React.Fragment>
        <>
          <div className="page-content">
            <XModal title={selectedSubject}
                    width={"lg"}
                    open={subjectDialog}
                    toggle={() => setSubjectDialog(!subjectDialog)}
                    onClose={() => setSubjectDialog(false)}>
              <SubjectAcademicDetails records={results} subject={selectedSubject} />
            </XModal>
            {loading && <Loading label="Loading student details. Please wait." />}

            <ToastContainer />
            {!loading && student && (
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <img
                            src={fullUrl(student.photo_path)}
                            alt="logo"
                            height="28"
                          />
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{student.firstname} {student.lastname}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{student.phone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Address</div>
                              <div className="dt-value">{student.address}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Class</div>
                              <div className="dt-value">{student.form} {student.stream}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{student.email}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Payment Code</div>
                              <div className="dt-value">{student.paymentcode}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Gender</div>
                              <div className="dt-value">{student.gender}</div>
                            </Col>
                          </Row>
                          <hr/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

                    {loadingResults && <Loading label="Loading academic records. Please wait." />}
                    {!loadingResults && (
                      <Row>
                          <Col md={3} lg={3} xl={3}> 
                            <Card>
                              <CardHeader className="justify-content-between d-flex align-items-center">
                                <CardTitle className="h4">Academic Records</CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="list-group">
                                  {Object.keys(results).map((form: string, idx1: number) => {
                                      return <>
                                        {results[form].map((record: any, idx2: number) => {
                                          if(selectedRecords == null && idx1 == 0 && idx2 == 0) {
                                            setSelectedRecords(record.records);
                                            setSelectedForm(form);
                                          }
                                          const isActive = (selectedRecords == record.setSelectedRecords) ? "active" : "";
                                          return <>
                                            <a href="#" 
                                              className= {`list-group-item list-group-item-action ${isActive}`}
                                              onClick={() => {
                                                setSelectedRecords(record.records);
                                                setSelectedForm(form);
                                              }}
                                            >
                                              {form} - Term: {record.term} {record.year}
                                            </a>
                                          </>
                                        })}
                                      </>
                                  })}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col>
                            {selectedRecords && (
                              <>
                                {Object.keys(selectedRecords.results).map((type: string) => {
                                  return <Card>
                                    <CardHeader className="justify-content-between d-flex align-items-center">
                                      <CardTitle className="h4">{type}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>SUBJECT</th><th>MARK</th><th>SCORE</th>
                                          </tr>
                                          {Object.keys(selectedRecords.results[type]).map((subject: string) => {
                                            if (selectedForm == "S5" || selectedForm == "S6") {
                                              return <tr>
                                                <td><a href="#" onClick={() => {
                                                  setSelectedSubject(subject);
                                                  setSubjectDialog(true);
                                                }}><i className="fa fa-folder"></i> {subject}</a></td>
                                                <td>{Object.keys(selectedRecords.results[type][subject].papers).map((paper: string) => {
                                                  return <>
                                                    <div>
                                                      <b>P{paper}:</b> {selectedRecords.results[type][subject].papers[paper].total}
                                                      &nbsp;|&nbsp;{selectedRecords.results[type][subject].papers[paper].grd}
                                                    </div>
                                                  </>
                                                })}</td>
                                                <td>{selectedRecords.results[type][subject].score}</td>
                                              </tr>
                                            } else {
                                              return <tr>
                                                <td><a href="#" onClick={() => {
                                                  setSelectedSubject(subject);
                                                  setSubjectDialog(true);
                                                }}><i className="fa fa-folder"></i> {subject}</a></td>
                                                <td>{selectedRecords.results[type][subject].mark}</td>
                                                <td>{selectedRecords.results[type][subject].score}</td>
                                              </tr>
                                            }
                                          })}
                                        </thead>
                                      </table>
                                    </CardBody>
                                  </Card>
                                })}

                                { selectedRecords.ncresults && (
                                  <>
                                    {Object.keys(selectedRecords.ncresults).map((type: string) => {
                                      return <Card>
                                        <CardHeader className="justify-content-between d-flex align-items-center">
                                          <CardTitle className="h4">{type}</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                          <table className="table table-bordered">
                                            <thead>
                                              {Object.keys(selectedRecords.ncresults[type]).map((subject: string) => {
                                                return <><tr>
                                                  <td colSpan={3}><b>{subject}</b></td>
                                                </tr>
                                                {Object.keys(selectedRecords.ncresults[type][subject].mark).map((topic: string) => {
                                                    return <tr>
                                                      <td>{topic}</td>
                                                      <td>{selectedRecords.ncresults[type][subject].mark[topic]}</td>
                                                      <td>{selectedRecords.ncresults[type][subject].score[topic]}</td>
                                                    </tr>
                                                  })}
                                                </>
                                              })}
                                            </thead>
                                          </table>
                                        </CardBody>
                                      </Card>
                                    })}                                    
                                  </>
                                )}
                              </>
                            )}
                          </Col>
                      </Row>
                    )}
            </Container>
            )}
          </div>
        </>
    </React.Fragment>
  );
};

export default StudentDetails;
